<template>
	<el-form ref="ruleForm" :model="ruleForm" :rules="rules" :label-width="$root.rwd < 3 ? '120px' : ''" :label-position="$root.rwd > 2 ? 'top' : 'right'">
		<h2 class="theme_text">訂購人資訊</h2>
		<el-divider />
		<el-form-item prop="orderer.name" label="姓名">
			<el-input v-model="ruleForm.orderer.name" class="round" />
		</el-form-item>
		<el-form-item prop="orderer.cellphone" label="手機號碼">
			<el-input v-model="ruleForm.orderer.cellphone" class="round" />
		</el-form-item>
		<el-form-item prop="orderer.email" label="電子信箱">
			<el-input v-model="ruleForm.orderer.email" class="round" />
		</el-form-item>
		<h2 class="theme_text">收件人資訊</h2>
		<el-divider />
		<el-form-item>
			<el-checkbox v-model="checked" @change="handleCheck" class="theme_text">與訂購人相同</el-checkbox>
		</el-form-item>
		<el-form-item prop="recipient.name" label="姓名">
			<el-input v-model="ruleForm.recipient.name" :disabled="checked" class="round" />
		</el-form-item>
		<el-form-item prop="recipient.cellphone" label="手機號碼">
			<el-input v-model="ruleForm.recipient.cellphone" :disabled="checked" class="round" />
		</el-form-item>
		<el-form-item prop="recipient.email" label="電子信箱">
			<el-input v-model="ruleForm.recipient.email" :disabled="checked" class="round" />
		</el-form-item>
		<el-form-item label="地址" required>
			<div class="row-grid" gutter-x="10">
				<el-form-item prop="recipient.zipcode">
					<el-input v-model="ruleForm.recipient.zipcode" :disabled="checked" placeholder="郵遞區號" style="width:auto;" class="round" />
				</el-form-item>
				<el-form-item prop="recipient.city">
					<el-select v-model="ruleForm.recipient.city" :disabled="checked" placeholder="縣市" class="round" @change="ruleForm.recipient.district = ''">
						<template v-for="(val, key, i) in administrativeDistrict">
							<el-option :value="key" :key="i" />
						</template>
					</el-select>
				</el-form-item>
				<el-form-item prop="recipient.district">
					<el-select v-model="ruleForm.recipient.district" :disabled="checked" placeholder="鄉鎮市區" class="round">
						<template v-for="(district, i) in (administrativeDistrict[ruleForm.recipient.city] || new Array)">
							<el-option :value="district" :key="i" />
						</template>
					</el-select>
				</el-form-item>
			</div>
			<el-form-item prop="recipient.address">
				<el-input v-model="ruleForm.recipient.address" :disabled="checked" placeholder="里(村)/路(街)/號/樓(室)" class="round" />
			</el-form-item>
		</el-form-item>
		<el-form-item prop="title" label="收據抬頭">
			<el-input v-model="ruleForm.title" class="round" />
		</el-form-item>
		<el-form-item prop="uniform_numbers" label="統編">
			<el-input v-model="ruleForm.uniform_numbers" class="round" />
		</el-form-item>
		<h2 class="theme_text">付款方式</h2>
		<el-divider />
		<div class="col" gutter="10">
			<el-radio v-model="ruleForm.payment" label="銀行匯款/ATM轉帳" />
			<el-radio v-model="ruleForm.payment" label="郵政劃撥" />
			<el-radio v-model="ruleForm.payment" label="郵政現金袋或郵局匯票" />
		</div>
		<br>
		<br>
	</el-form>
</template>

<script>
import administrativeDistrict from "@/assets/data/administrative-district.json";
import { mapState } from "vuex";
import { rule } from "@/utils/shared-methods";
const ruleForm = function () {
	return {
		orderer: {
			name: "",
			cellphone: "",
			email: "",
		},
		recipient: {
			name: "",
			cellphone: "",
			email: "",
			zipcode: "",
			city: "",
			district: "",
			address: "",
		},
		payment: "銀行匯款/ATM轉帳"
	}
};
const rules = {
	email: [
		{ required: true, message: "必填" },
		{ type: "email", message: "請填正確信箱" }
	],
	name: rule(),
	cellphone: rule(/^[0-9]{10}$/, "請填寫正確手機（10位數字）"),
	city: rule(),
	district: rule(),
	zipcode: rule(),
	address: rule(),
}
export default {
	data() {
		return {
			administrativeDistrict,
			checked: false,
			ruleForm: new ruleForm,
			rules: {
				orderer: rules,
				recipient: rules,
			},
		}
	},
	computed: {
		...mapState("auth", ["_auth"]),
	},
	methods: {
		handleCheck(val) {
			if (val) this._.assign(
				this.ruleForm.recipient,
				{
					...this._.cloneDeep(this.ruleForm.orderer),
					...this._.pick(this._auth, ["zipcode", "city", "district", "address"])
				}
			);
		},
		async validate() {
			return new Promise((resolve, reject) => {
				this.$refs["ruleForm"].validate((valid, object) => {
					this.handleCheck(this.checked);
					if (valid) resolve(this.ruleForm);
					else reject("請確認表格皆填寫完畢");
				});
			});
		},
		resetFields() {
			this.ruleForm = new ruleForm;
			this.$refs["ruleForm"].resetFields();
		}
	},
	created() {
		if (this._auth) {
			this._.assign(this.ruleForm.orderer, {
				name: this._auth.name,
				cellphone: this._auth.cellphone,
				email: this._auth.email,
			});
		}
	}
}
</script>

<style lang="scss" scoped>
.el-divider {
	height: 2px;
	background: $theme-light;
	margin-top: 0;
}
</style>